import React from 'react';
import './Card.css';

function Card({ title, content, image, imagePosition }) {
    return (
        <div className="card">
            {image && <img src={image} alt={title} className="card-image" />}
            <div className="card-content">
                <h3>{title}</h3>
                <p>{content}</p>
            </div>
        </div>
    );
}

export default Card;
